<template>
    <div id="app">
        <div style="margin: 0 auto;height: auto;background: #FFFFFF">
            <div style="padding-top: 115px;margin-left: 200px;">
                <div style="height: 80px;width: 1500px;position: relative;">
                    <div style="width: 1150px;float: left;background-color: white;border-radius: 5px;border: 2px solid #FF9800;">
                        <input v-model="like" type="" name="" placeholder="请输入你想要查询的内容以获得产品帮助......" style="height: 40px;width: 70% ;border: none;float: left;margin-top: 5px;font-size: 16px;padding-left: 20px;outline: none;">
                        <div @click="shopinput" style="height: 50px;width: 110px;float: right;cursor: pointer;">
                            <div style="background-color: #FF9800; height: 50px;width: 110px;border-radius: 5px;display: flex;justify-content: center; align-items:center;font-size: 20px"><svg t="1673238556897" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2759" width="20" height="20"><path d="M652.519 165.796c-134.397-134.395-352.323-134.395-486.721 0-134.397 134.41-134.397 352.332 0 486.727 130.861 130.858 340.852 134.249 475.878 10.316L937.84 959 959 937.841 662.835 641.666C786.784 506.657 783.379 296.655 652.519 165.796zM631.358 631.363c-122.517 122.515-321.884 122.515-444.4 0-122.517-122.514-122.517-321.878 0-444.393 122.517-122.529 321.884-122.529 444.4 0C753.875 309.485 753.875 508.849 631.358 631.363z" p-id="2760" fill="#707070"></path></svg>搜索</div>
                        </div>
                    </div>
                    <div style="margin: 10px 0 0 50px;float: left">
                        <div style="float: left;">
                            <el-button type="primary">发布商业需求</el-button>
                        </div>
                    </div>
                    <!--   分类  -->
                    <div class="tag_list" :style="{maxHeight:maxHeight+'px'}" style="width: 1500px;border: 1px solid #DCDCDC;background: #FFFFFF;float: left;margin-top: 30px;">
                        <div v-for="(item,index) in TypeList" :key="index">
                            <div style="height: 24px;font-size: 20px;font-weight: bold;margin: 15px 0 0 10px;cursor: pointer" @click="details(item.value)" :class="{codes:item.value === type}">{{item.label}}</div>
                            <div style="height: 30px;border-left: 1px solid rgb(220, 220, 220);">
                                <div v-for="(item2,index2) in item.children" :key="index2">
                                    <div style="float: left;margin-left: 10px;cursor:pointer;font-size: 16px;margin-top: 5px" @click="details(item2.value)" :class="{codes:item2.value === type}">{{item2.label}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="op sc hand" style="position: absolute;right:15px;top:86px;color:#ff9800;height:35px;line-height:35px;cursor:pointer" @click="maxHeight = 900" v-if="maxHeight == 60">更多 <i class="el-icon-caret-bottom"></i></div>
                        <div class="op sc hand" style="position: absolute;right:15px;top:86px;color:#ff9800;height:35px;line-height:35px;cursor:pointer" @click="maxHeight = 60" v-if="maxHeight == 900">收起 <i class="el-icon-caret-top"></i></div>
                    </div>
                    <div @click="sort=0" :style= "sort == 0?'width:152px;height: 50px;color:#E31436;border: 1px solid #E31436;':''" style="width: 152px;height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;float: left;cursor: pointer">
                        <div style="width: 72px;height: 21px;font-size: 18px;margin: 11px 0 0 40px;font-weight: bold;">默认推荐</div>
                    </div>
                    <div @click="sort=1" :style= "sort == 1?'width:152px;height: 50px;color:#E31436;border: 1px solid #E31436;':''" style="width: 152px;height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;float: left;cursor: pointer">
                        <div style="width: 72px;height: 21px;font-size: 18px;margin: 11px 0 0 40px;font-weight: bold;">销量最多</div>
                    </div>
                    <div @click="sort=2" :style= "sort == 2?'width:152px;height: 50px;color:#E31436;border: 1px solid #E31436;':''" style="width: 152px;height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;float: left;cursor: pointer">
                        <div style="width: 72px;height: 21px;font-size: 18px;margin: 11px 0 0 40px;font-weight: bold;">最新上架</div>
                    </div>
                    <div style="width: 1035px;height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;">
                        <div style="width: 72px;height: 21px;font-size: 18px;color: #444444;margin: 11px 0 0 40px;font-weight: bold;float: left">价格区间</div>
                        <div style="width: 140px;height: 36px;background: #F8F8F8;margin: 4px 0 0 30px;float: left">
                            <el-input placeholder="" prefix-icon="el-icon-coin" v-model="start" type="number"></el-input>
                        </div>
                        <div style="width: 12px;height: 21px;font-size: 18px;color: #444444;float: left;margin: 11px 0 0 10px">-</div>
                        <div style="width: 140px;height: 36px;background: #F8F8F8;margin: 4px 0 0 10px;float: left">
                            <el-input placeholder="" prefix-icon="el-icon-coin" v-model="end" type="number"></el-input>
                        </div>
                        <el-button style="float: left;margin: 6px 0 0 28px;" @click="shopsearch" size="medium" type="primary">搜索</el-button>
                        <div style="width: 130px;height: 21px;font-size: 18px;color: #444444;float: right;margin: 12px 0 0 37px;">产品共<span style="color:#E31436">{{ShopGoods.length}}</span>个</div>
                    </div>
                    <div style="clear: both">
                        <div v-for="(item,index) in ShopGoods" :key="index" style="width: 248px;height: 432px;background: #FFFFFF;border: 1px solid rgb(220, 220, 220);float: left;margin-top: 10px;">
                        <div style="width: 94px;height: 24px;font-size: 20px;color: #FF0000;margin: 17px 0 0 17px;float: left">￥{{item.price}}</div>
                        <div style="width: 64px;height: 16px;font-size: 14px;color: #999999;float: right;margin-right: 20px;margin-top: 20px;"><span style="color: #FF9800;">3542</span>销量</div>
                        <div style="width: 205px;height: 37px;font-size: 14px;color: #444444;margin: 10px 0 0 20px;float: left">{{item.title}}</div>
                        <div v-for="(i,k) in item.images" :key="k" >
                            <img :src="i.url" v-if="k === 0" style="width: 214px; height: 200px;margin-bottom: 10px;border: 1px solid #DCDCDC;margin-left: 18px;border-radius: 5px;object-fit: cover">
                            <div style="margin-left: 20px;">
                                <img v-if="k < 5" :src="i.url"  style="width: 40px;height: 36px;margin-right: 3px;float: left;border-radius: 5px;object-fit: cover">
                            </div>
                        </div>
                        <div style="clear: both">
                            <div style="width: 50px;height: 50px;margin: 14px 0 0 20px;float: left">
                                <img width="100%;" height="100%" style="border-radius: 50%" :src="item.shopInfo.logo.img_url">
                            </div>
                            <div style="margin: 20px 0 0 8px;width: 160px;height: 18px;font-size: 16px;color: #666666;float: left">{{item.shopInfo.name}}</div>
                            <div style="width: 18px;height: 18px;background: #0B9D35;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 12px">
                                <svg t="1672312156119" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3682" width="18" height="20"><path d="M558.545455 801.303273V332.055273l170.565818 184.785454a34.909091 34.909091 0 1 0 51.293091-47.383272L549.282909 219.066182a34.955636 34.955636 0 0 0-51.293091 0l-231.121454 250.414545a34.862545 34.862545 0 0 0 1.954909 49.338182 34.909091 34.909091 0 0 0 49.338182-2.001454L488.727273 332.055273v469.248a34.909091 34.909091 0 1 0 69.818182 0" fill="#ffffff" p-id="3683"></path></svg>
                            </div>
                            <div style="width: 58px;height: 18px;background: #FF9800;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 6px">
                                <div style="height: 14px;font-size: 12px;color: #FFFFFF;margin: 2px 0 0 4px">10年老店</div>
                            </div>
                            <div style="width: 75px;height: 16px;font-size: 14px;color:#999999;float: left;margin: 7px 0 0 8px;">好评率<span style="font-size: 14px;color: #FF9800">98%</span></div>
                        </div>

                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    components: {

    },
    computed: {

    },
    data () {
        return {
            like:"",
            TypeList:[],
            maxHeight:900,
            start:"",
            end:"",
            sort:0,
            ShopGoods:[],
            options:[],
            value:"",
            type:0,
        }
    },
    mounted:function(){
        this.getEquipmentTypeList()
        this.getAllShopGoods()
    },
    methods:{
        getEquipmentTypeList(){
            var that = this;
            this.newApi.getEquipmentTypeList({
                lv:3
            }).then((res)=>{
                that.TypeList = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 获取所有的服务
        getAllShopGoods(){
            var that= this;
            that.newApi.getAllShopGoods({}).then((ret)=>{
                that.ShopGoods = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        details(typeId){
            var that = this;
            that.type = typeId
        },
        shopsearch(){
            var that= this;
            var params = {};
            params.typeId = that.type;
            if (that.start == ""){
                that.utils.err("请输入最大值")
                return
            }
            params.minMoney = that.start;
            if (that.end == ""){
                that.utils.err("请输入最小值")
                return
            }
            params.maxMoney = that.end;
            params.page = 1;
            params.pageSize = 20;
            params.sort = that.sort;

            that.newApi.getGoodsList(params).then((res)=>{
                if(res.isSuccess == 1){
                    that.ShopGoods = res.data;
                    that.utils.sus('已加载全部')
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        shopinput(){
            var that= this;
            that.newApi.getGoodsList({
                title:that.like
            }).then((ret)=>{
                if(ret.isSuccess == 1) {
                    that.ShopGoods = ret.data;
                    that.like = ""
                    that.utils.sus('已加载全部')
                }
            }).catch((err)=>{
                console.log(err)
            })
        }

    }
}
</script>

<style scoped>
.tag_list {
    overflow:hidden;
}
.codes {
    color: red;
}

</style>

